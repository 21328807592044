import React from 'react';
import { graphql } from 'gatsby';
import parse from 'html-react-parser';

import Layout from '../layouts';
import Container from '../components/Container';
import Row from '../components/Container/row';
import Col from '../components/Container/column';

import '../components/ProductCategory/style.css';

import loadComponents from '../components/Loadable';
import loadLibrary from '../components/Loadable/library';

const Img = loadLibrary('gatsby-image');

const Products = loadComponents('product-category');
const Portal = loadComponents('portal');
const SubpageHeader = loadComponents('subpage-header');
const Alert = loadComponents('alert');

export default function ProductCategoryTemplate({ location, data }) {
  const previousPage = location.state && location.state.prevPage;
  const category = data.wpProductCategory;
  const products = data.allWpSimpleProduct.edges;
  return (
    <Layout
      title={category.name}
      seo={category.seo}
      location={category.link}
      prevPage={previousPage}
      padding="0 0 5.625rem"
      cart
    >
      <article>
        <SubpageHeader
          title={category.name}
          link={category.link}
          // parent={{ title: 'Apply Now', link: '/apply-now/' }}
        />
        <Container>
          {products.length > 0 ? (
            <>
              {category.description && (
                <Row>
                  <Col
                    style={{
                      marginBottom: `60px`,
                      paddingBottom: `60px`,
                      borderBottom: `1px dotted rgba(0,0,0,0.1)`,
                    }}
                  >
                    <div id="catDescription">{parse(category.description)}</div>
                    {category.image && (
                      <Portal portal="catDescription" description>
                        <Img
                          image={
                            category.image.localFile.childImageSharp
                              .gatsbyImageData
                          }
                          alt={category.name}
                        />
                      </Portal>
                    )}
                  </Col>
                </Row>
              )}
              <Products data={products} />
            </>
          ) : (
            <Alert error>
              We are not currently accepting visa applications from this
              location. Please check back soon
            </Alert>
          )}
        </Container>
      </article>
    </Layout>
  );
}

export const productCategoryQuery = graphql`
  query ($id: String!) {
    wpProductCategory(id: { eq: $id }) {
      name
      link
      description
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(height: 100, layout: FULL_WIDTH)
          }
        }
      }
      ...WooCategorySeo
    }
    allWpSimpleProduct(
      sort: { fields: date, order: ASC }
      filter: {
        productCategories: { nodes: { elemMatch: { id: { eq: $id } } } }
      }
    ) {
      edges {
        node {
          ... on WpSimpleProduct {
            id
            databaseId
            name
            link
            shortDescription
            regularPrice
            salePrice
            onSale
            price
            options {
              currency
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 576
                      height: 576
                      layout: CONSTRAINED
                    )
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
